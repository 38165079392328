<template>
  <div class="show-img-wrapper">
    <div class="_pic">
      <template v-for="(item ) of imageList">
        <img :src="item.resources_img" alt="" @click="make_jumpUrl(item.link_url)">
      </template>
    </div>
    <div class="_bottom_fixed" v-if="imageList.length>0">
      <div class="_height"></div>
      <div class="_bottom_back">
        <span class="_back_home" @click="$router.push('/home')"></span>
        <span class="_back_prev" @click="jumpGoBack()"></span>
      </div>
    </div>
    <div class="_right_bar_new_card"
         @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/applyDebitCard.do')">
      <img src="https://pic.eaworks.cn/scbank/uploads/2023922/6294112.png" alt="">
    </div>

  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import page_links from "../mixin/page_links";

export default {
  components: {},
  mixins: [page_links],
  data() {
    return {
      label: "",
      imageList: [],
      shareTitle: "四川银行-安逸生活 从此开启",
      shareImg: "https://pic.eaworks.cn/scbank/uploads/202284/1078189.png",
      shareLink: "https://scb.eaworks.cn/",
      shareDesc: "安逸生活 从此开启"
    };
  },

  created() {
    console.log("-> [index.vue] created");
  },
  watch: {
    '$route'(to, from) {
      this.getImageShowData();
    }
  },
  mounted() {
    this.getImageShowData();
    this.getJsInfo();
  },

  methods: {
    make_jumpUrl(url) {
      if (this.$router.history.current.path === url.substr(0, url.indexOf("?"))) {
        url = this.$router.history.current.path + "2" + url.substr(url.indexOf("?"));
      }
      this.onClickPageLink(url)
    },
    jumpGoBack() {
      if (this.$route.query.callbackUrl) {
        window.location.href = this.$route.query.callbackUrl;
      } else {
        this.$router.go(-1);
      }
    },
    async getJsInfo() {
      if (!window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) {
        // return;
      }

      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code === 200) {
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ];
        wx.config(data.res);
      }
      let _this = this;
      wx.ready(function () {
        let shareTitle = "四川银行-安逸生活 从此开启";
        let shareImg = "http://pic.eaworks.cn/scbank/uploads/202284/1078189.png";
        let shareLink = "http://scb.eaworks.cn/";
        let shareDesc = "安逸生活 从此开启";
        if (_this.label === "万源专区首页") {
          shareImg = "http://pic.eaworks.cn/scbank/uploads/2024829/8990373.png";
          shareTitle = "四川银行携手万源 共绘乡村振兴美好未来";
          shareLink = "http://scb.eaworks.cn/page#/showLabel?label=%E4%B8%87%E6%BA%90%E4%B8%93%E5%8C%BA%E9%A6%96%E9%A1%B5"
          shareDesc = "安逸生活 | 万源专属权益活动专区"
        }
        wx.updateTimelineShareData({
          title: shareTitle,
          link: shareLink,
          imgUrl: shareImg,
          success: function () {
            // 设置成功
          }
        });
        wx.updateAppMessageShareData({
          title: shareTitle,
          desc: shareDesc,
          link: shareLink,
          imgUrl: shareImg,
          success: function () {
            // 设置成功
          }
        });
      });
      wx.error(function (res) {
        console.info("wx error", res);
      });
    },
    async getImageShowData() {
      this.label = this.$route.query.label;
      if (this.label) {
        // 根据标签获取资源列表
        let {data} = await this.$ajax.gateway("/api/api_get_resource_by_label", {
          "body": {
            "label": this.label
          }
        });
        this.imageList = data;
      }
    },
  }
};
</script>

<style lang="less" scoped>
.show-img-wrapper {
  min-height: 20rem;
  background: #f6f6f6;

  ._pic {
    img {
      display: block;
      width: 100%;
    }
  }

  ._right_bar_new_card {
    position: fixed;
    top: 0.3rem;
    right: 0;

    img {
      height: 2.7rem;
    }
  }

  ._right_bar_new_card_JDK {
    position: fixed;
    top: 3.1rem;
    right: 0;

    img {
      height: 4rem;
    }
  }

}
</style>
